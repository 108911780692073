import Alpine from "alpinejs";

Alpine.data("imei_iccid_validation", () => ({
  validateNumbers: function (v, numberType) {
    let numberRegex;
    switch (numberType) {
      case "imei":
        numberRegex = /^[0-9]{15,16}$/;
        break;
      case "iccid":
        numberRegex = /^[0-9]{18,22}$/;
        break;
    }
    return numberRegex.test(v);
  },
}));
