import Alpine from "alpinejs";

Alpine.data("tracking_code_validation", () => {
  const lastCarrier =
    document.getElementById("motion_form").dataset.lastUsedCarrier;

  return {
    selected_carrier: lastCarrier || "",
    return_selected_carrier: "",
    validateTrackingCode: function (v, selectedCarrier) {
      let carrierSpecificRegex;
      switch (selectedCarrier) {
        case "PostNL":
          carrierSpecificRegex = /^[a-zA-Z0-9]{12,13}$/;
          break;
        case "DhlExpress":
          carrierSpecificRegex = /^[a-zA-Z0-9]{10}$/;
          break;
        case "DPD":
          carrierSpecificRegex = /^[a-zA-Z0-9]{14}$/;
          break;
        case "UPS":
          carrierSpecificRegex = /^[a-zA-Z0-9]{18}$/;
          break;
        default:
          carrierSpecificRegex = /^[a-zA-Z0-9]{8,40}$/;
      }
      return carrierSpecificRegex.test(v);
    },
  };
});
